




import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Logout extends Vue {
  // methods
  created() {
    this.$store.dispatch("logoutUser").then(() => {
      this.$router.push({ name: "login" });
    });
  }
}
